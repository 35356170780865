import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyEight.scss";
import NewsPhotoTwentyEight from "../../../assets/images/Photos/NewsPhotoTwentyEight.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyEight = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">November 18, 2024</span>
        <h3 className="article-page__title">
          World Day of Remembrance for Road Traffic Victims
        </h3>
        <p className="article-page__text">
          Each year, on the third Sunday of November, the World Day of
          Remembrance for Road Traffic Victims provides a moment to honour the
          millions of lives lost or affected by road traffic accidents
          worldwide. This day, observed globally, acknowledges the impact of
          road crashes on individuals, families, and communities.
          <br></br>
          <br></br>
          World Day of Remembrance serves not only to mourn the victims but also
          to raise awareness about road safety and the urgent need for action.
          With over 1.35 million people dying each year on the world’s roads,
          road traffic injuries are one of the leading causes of death globally,
          particularly among young people ranging from ages 5-29 years old.
          <br></br>
          <br></br>
          The day calls for stronger efforts to prevent road crashes through
          better legislation, safer road infrastructure, improved vehicle safety
          standards, and more effective public education campaigns. It also
          highlights the need for continued support for victims and their
          families.
          <br></br>
          <br></br>
          The World Day of Remembrance reminds us that road safety is a shared
          responsibility and that every action counts in creating safer roads
          for future generations.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentyEight}
          alt="Two Cars at an accident."
        />

        <div className="article-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/rsg-international-introduces-inclusive-ppe-in-partnership-with-marks-commercial"
            className="nav-button"
          >
            Previous
          </Link>
          <Link
            to="/national-safe-driving-week"
            className="nav-button"
          >
            Next
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyEight;
