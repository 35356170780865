import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyNine.scss";
import NewsPhotoTwentyNine from "../../../assets/images/Photos/NewsPhotoTwentyNine.jpeg";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyNine = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">December 1, 2024</span>
        <h3 className="article-page__title">National Safe Driving Week</h3>
        <div className="article-page__text">
          <div className="article-page__text">
            <p className="article-page__text">
              National Safe Driving Week aims to raise awareness about safe
              driving practices and reduce accidents on the road. With thousands
              of lives lost each year in preventable crashes, this week serves
              as a reminder for all drivers to prioritize safety and
              responsibility behind the wheel.
            </p>
            <br></br>
            <p className="article-page__text">Key Safety Tips:</p>
            <br></br>
            <p className="article-page__text">
              1. Avoid Distractions: Put your phone away and focus on the road
              to prevent accidents caused by texting or other distractions.
            </p>
            <br></br>
            <p className="article-page__text">
              2. Obey Speed Limits: Always follow posted speed limits and adjust
              your speed for road conditions.
            </p>
            <br></br>
            <p className="article-page__text">
              3. Don’t Drive Under the Influence: Never drive after consuming
              alcohol, drugs, or medications that impair your judgment.
            </p>
            <br></br>
            <p className="article-page__text">
              4. Buckle Up: Always wear your seatbelt, no matter how short the
              trip.
            </p>
            <br></br>
            <p className="article-page__text">
              5. Maintain Your Vehicle: Ensure your vehicle is in good working
              condition, including tires, brakes, and lights.
            </p>
            <br></br>
            <p className="article-page__text">
              6. Drive Defensively: Stay alert for other drivers and anticipate
              potential hazards.
            </p>
            <br></br>
            <p className="article-page__text">
              7. Avoid Driving Tired: If you’re feeling fatigued, take a break
              to rest.
            </p>
            <br></br>
            <p className="article-page__text">
              Safe driving is a shared responsibility. Drivers must make smart
              choices, and passengers should encourage safe behaviours. National
              Safe Driving Week is a chance to reflect on how we can all
              contribute to safer roads. By adopting safe driving practices, we
              can reduce accidents and save lives. Let’s make a commitment to
              drive responsibly—not just this week, but every day.
            </p>
          </div>
        </div>

        <img
          className="article-page__image"
          src={NewsPhotoTwentyNine}
          alt="Drivers putting on their seatbelts."
        />

        <div className="last-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/world-day-of-rememberance-for-road-traffic-victims" // Update the link to the previous article
            className="nav-button"
          >
            Previous
          </Link>
          {/* Add next button if there is a following article */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyNine;
