import "./WhistleblowerPolicy.scss";

import WhistleblowerPhoto from "../../assets/images/Photos/Whistleblower.jpg"
import WhistleblowerPolicyPDF from "../../assets/files/PDFs/Whistleblower Policy - Updated Nov 2024.pdf"
import Footer from "../../components/Footer/Footer";


function WhistleblowerPolicy() {
  return (
    <>
      <div className="whistleblower-container">
        <h1 className="whistleblower-title">Our Whistleblowing Service</h1>
        <div className="whistleblower-content">
          <div className="whistleblower-textbox">
            <h2 className="whistleblower-subtitle">Doing What’s Right</h2>
            <p className="whistleblower-text">
              At RSG International, safety, respect, and transparency are at the
              core of everything we do. Upholding high ethical standards is crucial
              to building trust within our organization and the communities we
              serve.
            </p>
            <h2 className="whistleblower-subtitle">Whistleblowing Service</h2>
            <p className="whistleblower-text">
              Our whistleblowing service offers a secure way to report concerns
              about potential wrongdoing that could impact individuals, our
              organization, society, or the environment. 
              {/* View our policy{" "} */}
              {/* <a
                href={WhistleblowerPolicyPDF}
                target="_blank"
                rel="noopener noreferrer"
                className="whistleblower-link"
              >
                here
              </a>. */}
            </p>
            <h2 className="whistleblower-subtitle">When to Use the Service</h2>
            <p className="whistleblower-text">
              If you notice actions that conflict with our ethical code or pose
              risks to people or the organization, we encourage you to use the
              whistleblowing service. While evidence is not required, reports must
              be made in good faith.
              <br></br>
              <br></br>
              Maintaining a transparent business climate and strong ethics is a
              shared responsibility. Your voice plays a vital role in our success.
              <br></br>
              <br></br>
              Send Your Message Securely:
              <br></br>
              <a
                className="whistleblower-link"
                href="https://report.whistleb.com/rsg"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://report.whistleb.com/rsg
              </a>
            </p>
          </div>
          <div className="whistleblower-photo-container">
            <img
              src={WhistleblowerPhoto}
              alt="Whistleblower Service"
              className="whistleblower-photo"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WhistleblowerPolicy;